.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--exo-palette-blue-10);
    height: 100%;
}

.login-card {
    width: var(--exo-size-4);
}
