.comment-section {
    padding: var(--spacing-common) var(--spacing-common) 0;
}

.comment-box {
    display: grid;
    grid-template-columns: 1fr;

    .comment-submit {
        justify-self: start;
    }
}
