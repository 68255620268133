.task-layout {
    display: grid;
    grid-template-columns: 1fr minmax(320px, 33%);
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'taskHeader taskHeader'
        'taskContent taskActivity';
    min-height: 100%;
    height: 100%;
    overflow: hidden;
}

.task-header {
    grid-area: taskHeader;
    display: flex;
    flex-flow: row nowrap;
    gap: var(--gap-common);
    padding: var(--spacing-common);
    align-items: center;
    background-color: var(--exo-color-background);
    border-bottom: 1px solid var(--exo-color-border);
    position: sticky;
    top: 0;
    z-index: 1;

    .title {
        font-size: clamp(18px, 2vw, 24px);
        display: flex;
        flex-flow: row;
        gap: var(--exo-spacing-x-small);
        align-items: baseline;
        margin: 0;
    }

    .title-separator,
    .title-step {
        font-weight: normal;
    }

    .title-separator {
        color: var(--exo-color-border);
    }
}

.task-content {
    grid-area: taskContent;
    display: grid;
    align-content: start;
    gap: var(--gap-common);
    background: linear-gradient(to bottom, var(--exo-color-body) 66%, var(--exo-palette-white));
    padding: var(--spacing-common) var(--spacing-adjusted);
    overflow-y: auto;
}

.task-sidebar {
    grid-area: taskActivity;
    display: grid;
    align-content: start;
    gap: var(--gap-common);
    border-left: 1px solid var(--exo-color-border);
    overflow-y: hidden;

    h4 {
        margin-top: 0;
        margin-bottom: var(--exo-spacing-medium);
    }
}

/* MOBILE VIEW TWEAKS */
@media only screen and (max-width: 1024px) {
    .task-layout {
        grid-template-rows: auto 1fr auto;
        grid-template-areas:
            'taskHeader taskHeader'
            'taskContent taskContent'
            'taskActivity taskActivity';
        height: auto;
        overflow: initial;
    }

    .task-header {
        .title {
            flex-flow: column;
            gap: var(--exo-spacing-2x-small);
        }

        .title-separator {
            display: none;
        }
    }

    .task-sidebar {
        border-top: 1px solid var(--exo-color-border);
        border-left: none;
        padding: var(--spacing-common) var(--spacing-adjusted);
    }
}

@media only screen and (max-width: 512px) {
    .task-content,
    .task-sidebar {
        padding: var(--spacing-common);
    }
}
