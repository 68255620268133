.table-filters {
    display: flex;
    flex-flow: row wrap;
    gap: var(--gap-common);
    align-items: center;

    .filter-search {
        width: clamp(320px, 25vw, 480px);
    }
}

@media screen and (max-width: 480px) {
    .table-filters .filter-search {
        width: 100%;
    }
}

.table-wrapper {
    /* readable table colours */
    --header-background: var(--exo-color-border);
    --header-background-hover: var(--exo-color-border-contrast);
    --row-background: var(--exo-color-background);
    --row-background-hover: var(--exo-color-background-secondary);
    --row-background-selected: var(--exo-color-background-selected-weak);

    background: var(--exo-color-page);

    /*
    Add some border radius and a thin outline,
    this combined with the border spacing on te table,
    will give us a uniform grid going around the table rows.
    */
    outline: 1px solid var(--header-background);
    border-radius: var(--exo-radius-standard);

    /* optional slight box shadow to make the table a bit less flat */
    box-shadow: 0 5px 10px -5px var(--header-background);

    /* make table scrollable horizontally on small screens */
    overflow-x: auto;

    /* custom scrollbar */
    &::-webkit-scrollbar {
        width: 15px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--exo-palette-gray-30);
        border-radius: 10px;
        background-clip: padding-box;
        border: 4px solid transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--exo-palette-gray-60);
    }
}

table {
    width: 100%;
    user-select: none;

    /*
    Tweak the spacing so only table rows are slightly separated,
    this combined with the outline and a border radius on the wrapper,
    will give us a nice grid going around the table rows.
    */
    border-collapse: separate;
    border-spacing: 0 1px;
    background-color: var(--header-background);
}

thead {
    background-color: var(--header-background);

    th {
        text-align: left;
        cursor: pointer;

        &:last-child {
            /* view button column size */
            width: 72px;
        }

        &:not(:last-child) {
            /* sort icon should not make Due Date column wider */
            min-width: 150px;
        }

        &:hover {
            background: var(--header-background-hover);
        }
    }

    .th-content {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: var(--exo-spacing-x-small);
    }

    .sort-icon {
        margin-left: var(--exo-spacing-x-small);
    }
}

tbody {
    tr {
        cursor: pointer;
        background: var(--row-background);

        &:last-child {
            --tweaked-radius: calc(var(--exo-radius-standard) - 1px);
            border-radius: 0 0 var(--tweaked-radius) var(--tweaked-radius);
        }

        &:hover {
            background: var(--row-background-hover);
        }

        &.selected-task {
            background: var(--row-background-selected);
            outline: 1px solid var(--exo-color-outline-moderate);
            outline-offset: -2px;
        }
    }

    td {
        padding: var(--exo-spacing-x-small);
    }

    .table-button::part(button) {
        width: fit-content;
    }
}

/*
Exo badges have some visual issues like unequal padding when using icons
and inconsistent fill/border colour combos, so we made our own while
still following the general exo badge style, but with some tweaks.
*/
.status-badge {
    display: inline-flex;
    flex-flow: row nowrap;
    gap: var(--exo-spacing-2x-small);
    align-items: center;
    padding: 4px 11px 4px 5px; /* custom values for a more balanced look */
    border-radius: var(--exo-radius-x-large);
    font: var(--exo-text-label-standard-semi-bold);
    color: var(--exo-color-font);

    &.done {
        background-color: var(--exo-color-background-success);
        border: 1px solid var(--exo-color-background-success-strong);
    }

    &.in-progress {
        background-color: var(--exo-color-background-selected-weak);
        border: 1px solid var(--exo-color-background-selected);
    }
}
