html,
body,
#root,
.app {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    /* fix exosphere white text issue */
    --exo-text-h2-color: var(--exo-color-font);
}

@media only screen and (max-width: 1024px) {
    html,
    body,
    #root,
    .app {
        overflow: auto;
    }
}
