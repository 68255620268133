@import url('../main.css');

.inbox-header,
.inbox-greeting,
.inbox-tabs,
.inbox-content {
    padding: var(--spacing-common);
}

.inbox-header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;

    .company-logo {
        display: flex;
        flex-flow: row nowrap;
        gap: var(--exo-spacing-x-small);
        align-items: center;
        font-size: 20px;
        color: var(--exo-color-font);

        /* used as temporary company logo, can be removed later */
        ex-icon {
            font-size: 36px;
        }
    }

    .notifications {
        position: relative;
        justify-self: end;
    }

    .notifications-counter {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-content: center;
        width: var(--exo-font-size-small);
        height: var(--exo-font-size-small);
        aspect-ratio: 1;
        font-size: var(--exo-font-size-micro);
        line-height: var(--exo-font-size-small);
        color: var(--exo-color-font-inverse);
        background-color: var(--exo-color-background-brand);
        border-radius: var(--exo-radius-circle);
        /* let the button have all the clicks */
        pointer-events: none;
    }
}

.inbox-greeting {
    .greeting {
        grid-column: span 2;
        font-size: clamp(24px, 2.5vw, 36px);
        width: fit-content;
        margin: 0;
    }
}

.inbox-tabs {
    background: var(--exo-color-page);
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid var(--exo-color-border);
    /* tweak the padding a bit */
    padding-top: var(--exo-spacing-small);
    padding-bottom: 0;

    .tab-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: var(--exo-spacing-2x-small);
    }
}

.inbox-content {
    display: grid;
    gap: var(--gap-common);
    background: linear-gradient(to bottom, var(--exo-color-body) 66%, var(--exo-palette-white));
}

/* give the text a gradient fill */
.text-gradient-fill {
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-position: center center;
    background-image: linear-gradient(
        to right,
        var(--exo-palette-purple-40),
        var(--exo-palette-periwinkle-50)
    );
    background-size: cover;
}
