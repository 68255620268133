@import url('../main.css');

.form-container {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gap-common) * 1.25);

    .workflow > h1 {
        font: var(--exo-text-h1);
    }

    .form-inputs {
        display: flex;
        flex-flow: column;
        gap: calc(var(--gap-common) * 0.25);
    }

    .form-actions {
        display: flex;
        flex-flow: row;
        gap: var(--gap-common);
    }

    @media only screen and (max-width: 512px) {
        .form-inputs,
        .form-actions {
            padding: 0;
        }
    }
}
