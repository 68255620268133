.activity-section {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: var(--spacing-common);

    h4 {
        padding: 0 var(--spacing-common);
    }
}

.activity-list-scroller {
    overflow-y: auto;
    padding: 0 var(--spacing-common);
}

.activity-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font: var(--exo-text-body-small);
    display: grid;
    gap: var(--gap-common);
    padding-bottom: var(--spacing-common);
}

.activity {
    border-radius: var(--exo-radius-standard);
    padding: var(--exo-spacing-x-small) var(--exo-spacing-small);
    border: 1px solid;
    width: 75%;
    display: grid;
    grid-template-areas:
        'icon title'
        'sidebar content'
        'sidebar footer';
    grid-template-columns: min-content 1fr;
    gap: 0 var(--exo-spacing-x-small);
    cursor: default;

    &.comment {
        color: var(--exo-palette-blue-90);
        background-color: var(--exo-palette-blue-10);
        justify-self: end;
    }

    &.event {
        color: var(--exo-palette-gray-90);
        background-color: var(--exo-palette-white);
    }
}

.activity-icon {
    grid-area: icon;
    font-size: 22px;
}

.activity-title {
    grid-area: title;
    font: var(--exo-text-body-medium);
    font-weight: 600;
    margin-bottom: var(--exo-spacing-small);
}

.activity-content {
    grid-area: content;

    strong {
        font-weight: 500;
    }
}

.activity-footer {
    grid-area: footer;
    font: var(--exo-text-body-micro);
    justify-self: end;
}
